import { ApexOptions } from 'apexcharts'
import { useCallback, useEffect, useState } from 'react'
import { getChartOptions } from 'app/utils/charts.util'
import { useTheme } from '@mui/material'

export const useChartOptions = function () {
  const [options, setOptions] = useState<ApexOptions>({} as ApexOptions)
  const theme = useTheme()

  const getOptions = useCallback(async () => {
    return await getChartOptions(
      {
        grid: {
          show: true,
          borderColor: '#FF0000'
        },
        theme: {
          mode: theme.palette.mode
        }
      },
      true
    )
  }, [theme.palette])

  useEffect(() => {
    getOptions().then((options) => setOptions(options))
  }, [getOptions])

  return options
}
