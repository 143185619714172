import React, { useEffect, useMemo } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'app/providers/fetcher.provider'
import { FiltersBox } from 'app/components/filters/filter-box'
import { useList } from 'app/providers/list.provider'
import { useApp } from 'app/providers/app.provider'
import { OccupancyChart } from 'modules/stats/components/occupancy-chart.component'
import { RangePeriodPicker } from 'app/components/filters/range-period-picker'
import dayjs from 'dayjs'

export const StatsRateOccupancyView = () => {
  const { filtersList, initFilters, handleFilter } = useList()
  const { searchParams, setSearchParams } = useFetcher()
  const { t } = useTranslation()
  const { user } = useApp()

  const labels = useMemo(() => {
    const startDate = dayjs(searchParams.get('date_begin') ?? dayjs().startOf('month')).startOf(
      'month'
    )
    const endDate = dayjs(searchParams.get('date_end') ?? dayjs().startOf('month')).endOf('month')
    const monthsDifference = endDate.diff(startDate, 'month')
    return Array.from({ length: monthsDifference + 1 }, (_, i) =>
      startDate.add(i, 'month').format('YYYY-MM-DD')
    )
  }, [searchParams])

  useEffect(() => {
    if (!user) return
    if (!searchParams.get('centerId')) {
      searchParams.set('centerId', String(user.mainCenter))
    }

    initFilters(new Map<string, string>([['centers', 'centerId']]), {
      centers: {
        multiple: false
      }
    }).then()
  }, [])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('occupation_rate')}
        </Typography>
      </Box>
      <Grid>
        <FiltersBox
          filters={filtersList}
          handleFilters={() => handleFilter(async () => {}, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        >
          <RangePeriodPicker
            slugStart="date_begin"
            slugEnd="date_end"
            labelStart={t('begin')}
            labelEnd={t('end')}
            onChange={() => handleFilter(async () => {}, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
        </FiltersBox>
      </Grid>
      <OccupancyChart height={350} params={searchParams} labels={labels} />
    </Container>
  )
}
