import React, { useCallback, useEffect, useState, useMemo } from 'react'
import {
  Box,
  Grid,
  Paper,
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  useTheme
} from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'app/providers/fetcher.provider'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { useFeedback } from 'app/providers/feedback.provider'
import { FiltersBox } from 'app/components/filters/filter-box'
import { SearchCenterInput } from 'app/components/filters/search-center-input'
import { useList } from 'app/providers/list.provider'
import styled from '@emotion/styled'
import { StatsCA } from 'api/models/stats'
import dayjs from 'dayjs'
import { CaChartComponent } from 'modules/stats/components/ca-chart.component'
import { RangePeriodPicker } from 'app/components/filters/range-period-picker'

const TableRowCustom = styled(TableRow)(() => ({
  th: {
    padding: '8px;'
  }
}))

export const StatsCAView = () => {
  const { t } = useTranslation()
  const { setSearchParams, searchParams, getStatsCA } = useFetcher()
  const { filtersList, initFilters, handleFilter } = useList()
  const { handleMutation } = useFeedback()
  const [rows, setRows] = useState<StatsCA>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [commonFilters] = useState<Map<string, string>>(new Map<string, string>([['', '']]))
  const theme = useTheme()
  const months = useMemo(() => {
    const start = dayjs(searchParams.get('date_begin')).startOf('month')
    const end = dayjs(searchParams.get('date_end')).endOf('month')
    const monthsArray = []

    for (
      let current = start;
      current.isBefore(end) || current.isSame(end, 'month');
      current = current.add(1, 'month')
    ) {
      monthsArray.push(current.format('MMMM'))
    }

    return monthsArray
  }, [searchParams])

  const refreshList = useCallback(async () => {
    await handleMutation({
      onStart: () => setIsLoading(true),
      mutation: getStatsCA,
      onSuccess: (data) => setRows(data),
      onEnd: () => setIsLoading(false)
    })
  }, [getStatsCA])

  useEffect(() => {
    initFilters(commonFilters).then(() => refreshList())
  }, [])

  const TableCellCustom = useCallback(
    ({ label }: { label: string }) => (
      <TableCell align="center">
        <Box
          sx={[
            label === 'year'
              ? { backgroundColor: 'black', padding: 2, color: '#fff' }
              : {
                  border: '1px solid ' + theme.palette.primary.main,
                  borderRadius: '2px',
                  padding: 2
                }
          ]}
        >
          <Typography variant="h5">{t(label).toUpperCase()}</Typography>
        </Box>
      </TableCell>
    ),
    [theme, t]
  )

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('detailed_sales_figures')}
        </Typography>
      </Box>
      <Grid>
        <FiltersBox
          filters={filtersList}
          handleFilters={() => handleFilter(refreshList, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        >
          <SearchCenterInput
            slug="centerId"
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <RangePeriodPicker
            slugStart="date_begin"
            slugEnd="date_end"
            labelStart={t('begin')}
            labelEnd={t('end')}
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            initialStartValue={dayjs().startOf('year').month(0)}
            initialEndValue={dayjs().endOf('year')}
          />
        </FiltersBox>
      </Grid>
      {isLoading ? (
        <CardSkeleton />
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRowCustom>
                <TableCellCustom label={'type'} />
                {months.map((month, index) => (
                  <TableCellCustom key={index} label={month} />
                ))}
                <TableCellCustom label={'year'} />
              </TableRowCustom>
            </TableHead>
            <TableBody>
              {rows !== undefined &&
                Object.entries(rows)
                  .filter(([name]) => name !== 'BP')
                  .map(([label, values], index) => {
                    let total = 0
                    let isTotal = label === 'Total'
                    return (
                      <TableRow
                        key={index}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          sx={[
                            isTotal && {
                              backgroundColor: theme.palette.secondary.light,
                              color: '#fff',
                              fontWeight: 'bold'
                            }
                          ]}
                        >
                          {label}
                        </TableCell>
                        {Object.values(values).map((value: number, index) => {
                          total += Number(value)
                          return (
                            <TableCell
                              sx={[
                                isTotal && {
                                  backgroundColor: theme.palette.secondary.light,
                                  color: '#fff',
                                  fontWeight: 'bold'
                                }
                              ]}
                              key={index}
                              align="right"
                            >
                              {value + ' €'}
                            </TableCell>
                          )
                        })}
                        <TableCell
                          sx={[
                            {
                              backgroundColor: theme.palette.primary.light,
                              color: '#fff',
                              fontWeight: 'bold'
                            }
                          ]}
                          align="right"
                        >
                          {total + ' €'}
                        </TableCell>
                      </TableRow>
                    )
                  })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <CaChartComponent rows={rows} isLoading={isLoading} height={350} params={searchParams} />
    </Container>
  )
}
