import { type Ctx, fetcher, formatParams, uploader, downloader } from 'api'
import { BASE_URL, API } from 'api/constants'
import {
  AvailableDesktopsSearch,
  MainList,
  MainService,
  MainSum,
  OptionService,
  ServicePrice,
  MainServiceBody,
  OptionServiceBody,
  ParkingServiceAddBody,
  ParkingServiceBody,
  IdReturn,
  MainServiceAddBody,
  MainServiceComputePriceBody,
  ConsumptionService,
  ConsumptionsServiceBody,
  ServicesTypesList,
  CheckExcelServiceResult,
  IdListReturn
} from 'api/models'
import { ConsumptionPricing, PricingConsumptionInputs } from 'api/models/pricing'
import urlHelper from 'app/helpers/url.helper'

export const servicesRouter = ({ token }: Ctx) => ({
  mainList: async (params: string) =>
    fetcher<MainList>({
      url: `${BASE_URL}${API.MAIN_SERVICES}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  mainGet: async (id: number) =>
    fetcher<MainService>({
      url: `${BASE_URL}${urlHelper(API.MAIN_SERVICE, { id })}`,
      method: 'GET',
      token: token
    }),
  optionGet: async (id: number) =>
    fetcher<OptionService>({
      url: `${BASE_URL}${urlHelper(API.OPTION_SERVICE, { id })}`,
      method: 'GET',
      token: token
    }),
  updateOption: async (id: number, data: OptionServiceBody) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.OPTION_SERVICE, { id })}`,
      method: 'PATCH',
      token: token,
      body: data
    }),
  createOption: async (data: OptionServiceBody) =>
    fetcher<void>({
      url: `${BASE_URL}${API.OPTION_SERVICES}`,
      method: 'POST',
      token: token,
      body: data
    }),
  createMain: async (data: MainServiceAddBody) =>
    fetcher<IdReturn>({
      url: `${BASE_URL}${API.MAIN_SERVICES}`,
      method: 'POST',
      token: token,
      body: data
    }),
  updateMain: async (id: number, data: MainServiceBody) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.MAIN_SERVICE, { id })}`,
      method: 'PATCH',
      token: token,
      body: data
    }),
  computePrice: async (data: MainServiceComputePriceBody) =>
    fetcher<{ computedPrice: number }>({
      url: `${BASE_URL}${API.COMPUTED_PRICE}?center=${data.center}&serviceType=${data.serviceType}${
        data.typology ? '&typology=' + data.typology : ''
      }${data.surface ? '&surface=' + data.surface : ''}`,
      method: 'GET',
      token: token
    }),
  parkingList: async (params: string) =>
    fetcher<any>({
      url: `${BASE_URL}${API.PARKING_SERVICES}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getParking: async (id: number) =>
    fetcher<MainService>({
      url: `${BASE_URL}${urlHelper(API.PARKING_SERVICE, { id })}`,
      method: 'GET',
      token: token
    }),
  createParking: async (data: ParkingServiceAddBody) =>
    fetcher<IdReturn>({
      url: `${BASE_URL}${API.PARKING_SERVICES}`,
      method: 'POST',
      token: token,
      body: data
    }),
  getConsumption: async (id: number) =>
    fetcher<ConsumptionService>({
      url: `${BASE_URL}${urlHelper(API.CONSUMPTION, { id })}`,
      method: 'GET',
      token: token
    }),
  updateConsumption: async (id: number, data: any) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.CONSUMPTION, { id })}`,
      method: 'PATCH',
      token: token,
      body: data
    }),
  createConsumption: async (data: ConsumptionsServiceBody) =>
    fetcher<void>({
      url: `${BASE_URL}${API.CONSUMPTIONS}`,
      method: 'POST',
      token: token,
      body: data
    }),
  updateParking: async (id: number, data: ParkingServiceBody) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.PARKING_SERVICE, { id })}`,
      method: 'PATCH',
      token: token,
      body: data
    }),

  getMainSum: async (params: string) =>
    fetcher<MainSum>({
      url: `${BASE_URL}${API.MAIN_SERVICES_SUM}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getMainTemplateSpreadsheet: async () =>
    downloader(
      {
        url: `${BASE_URL}${API.MAIN_TEMPLATE_SPREADSHEET}`,
        method: 'GET',
        token: token
      },
      'upload_services_example.xlsx'
    ),
  getConsumptionPricing: async ({
    serviceId,
    unitprice,
    quantity,
    fees,
    reduction
  }: PricingConsumptionInputs) =>
    fetcher<ConsumptionPricing>({
      url: `${BASE_URL}${urlHelper(API.SERVICE_CONSUMPTIONS_PRICING, {
        id: serviceId
      })}?${new URLSearchParams({
        unitprice: String(unitprice),
        fees: String(fees),
        quantity: String(quantity),
        reduction: String(reduction)
      })}`,
      method: 'GET',
      token: token
    }),
  getPricing: async (id: string, { center, contract, client, begin, end, owner, reduction }: any) =>
    fetcher<ServicePrice>({
      url: `${BASE_URL}${urlHelper(API.SERVICE_PRICING, {
        id: id
      })}?${new URLSearchParams({
        center: String(center),
        contract: String(contract),
        client: String(client),
        begin: String(begin),
        end: String(end),
        owner: String(owner),
        reduction: String(reduction)
      })}`,
      method: 'GET',
      token: token
    }),
  getAvailableDesktops: async (data: AvailableDesktopsSearch) =>
    fetcher<any>({
      url: `${BASE_URL}${API.SERVICES_AVAILABLE_DESKTOPS}?${new URLSearchParams(data)}`,
      method: 'GET',
      token: token
    }),
  getAvailable: async (data: any) =>
    fetcher<any[]>({
      url:
        `${BASE_URL}${API.SERVICES_AVAILABLE}?center=${data.center}&begin=${data.begin}&end=${data.end}` +
        `&commitment=${data.commitment}&serviceIds=[${data.serviceIds.join(',')}]` +
        `&originalServiceIds=[${data.originalServiceIds}]&contractType=${data.contractType}`,
      method: 'GET',
      token: token
    }),
  getCommitmentPrice: async (id: string, commitment: number) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.SERVICE_COMMITMENT_PRICE, {
        id
      })}?commitment=${commitment}`,
      method: 'GET',
      token: token
    }),
  getServiceAvailability: async (id: string, begin?: string) => {
    let url = `${BASE_URL}${urlHelper(API.SERVICE_AVAILABILITY, { id })}`
    if (begin !== undefined) {
      url += `?begin=${begin}`
    }
    return fetcher<{ message: string }>({
      url: url,
      method: 'GET',
      token: token
    })
  },
  getServicesTypes: async () =>
    fetcher<ServicesTypesList>({
      url: `${BASE_URL}${API.SERVICES_TYPES}`,
      method: 'GET',
      token: token
    }),
  checkAddManyExcel: async (formData: FormData) =>
    uploader<CheckExcelServiceResult>({
      url: `${BASE_URL}${API.CHECK_ADD_MANY_SERVICE_EXCEL}`,
      method: 'POST',
      token: token,
      body: formData
    }),
  createManyMain: async (formData: FormData) =>
    uploader<IdListReturn>({
      url: `${BASE_URL}${API.POST_MANY_MAIN_SERVICES}`,
      method: 'POST',
      token: token,
      body: formData
    })
})
