import React, { useMemo, useState } from 'react'
import { Box, Grid, Typography, Paper as MuiPaper, Tooltip } from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useApp } from 'app/providers/app.provider'
import { ParkingsOccupationStats } from 'modules/stats/components/parkings-occupation-stats.component'
import { dates, RangePicker } from 'app/components/filters/range-picker'
import { FiltersContainer } from 'app/components/filters/filters-container'
import TableParkingsOccupation from 'modules/stats/components/table-parkings-occupation.component'
import { Info } from '@mui/icons-material'

export const StatsParkingsView = () => {
  const { t } = useTranslation()
  const { user } = useApp()
  const { setSearchParams, searchParams } = useFetcher()
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([['centers', 'center']])
  )

  const paramsFilters = useMemo(() => {
    return {
      centers: {
        multiple: false,
        default: user?.mainCenter
      }
    }
  }, [user])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('stats_parkings_management')}
        </Typography>
      </Box>
      <Grid>
        <FiltersContainer
          commonFilters={commonFilters}
          paramsFilters={paramsFilters}
          onChange={() => {}}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        >
          <RangePicker
            slugs={{
              begin: 'begin',
              end: 'end'
            }}
            label={t('date')}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            valueLabel={dates.LAST_12_MONTHS}
            slug={'dates'}
          />
        </FiltersContainer>
      </Grid>
      <Grid container spacing={6} alignItems={'stretch'}>
        <Grid item xs={12} sm={12} md={6}>
          <MuiPaper>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              paddingTop={6}
              paddingX={6}
            >
              <Typography variant="h3">{t('parkings_occupation')}</Typography>
            </Box>
            <ParkingsOccupationStats />
          </MuiPaper>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <MuiPaper sx={{ height: '100%' }}>
            <Box display="flex" justifyContent="start" alignItems="center" gap={4} padding={6}>
              <Typography variant="h3">{t('stats_parkings_clients_occupation')}</Typography>
              <Tooltip title={t('tooltip_stats_parkings_clients_occupation')} placement="top-start">
                <Info fontSize={'small'} />
              </Tooltip>
            </Box>
            <TableParkingsOccupation center={Number(searchParams.get('center'))} />
          </MuiPaper>
        </Grid>
      </Grid>
    </Container>
  )
}
