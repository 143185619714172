export const convertHexToRgbA = (hex: string, opacity: number) => {
  hex = hex.replace(/^#/, '')

  const bigint = parseInt(hex, 16)
  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255

  return `rgb(${r} ${g} ${b} / ${opacity}%)`
}

export const ChartBpColor = '#ff8219'

export const chartPalette = [
  '#C4F5FC', // Light cyan
  '#613F75', // Finn
  '#156064', // Caribbean
  '#B7FFD8', // Aquamarine
  '#F5D7E3', // Mimi pink
  '#4357AD', // Violet blue
  '#C9F299', // Mindaro
  '#E55381', // Blush
  '#FFF07C', // Maize
  '#52D1DC' // Robin egg blue
]

export const reversedChartPalette = chartPalette.toReversed()
