import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useQuery } from '@tanstack/react-query'
import { Box, Card, CardContent } from '@mui/material'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { ThemedChart } from 'app/components/charts/timeline.component'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { ApexOptions } from 'apexcharts'
import dayjs from 'dayjs'
import { useChartOptions } from 'app/hooks/use-chart-options.hook'
import merge from 'deepmerge'
import { useTranslation } from 'react-i18next'
import { formatPercentage, formatRate } from 'app/utils/format'
import { styled } from '@mui/system'

const StatsWithNoLine = styled(ThemedChart)`
  .apexcharts-line-series.apexcharts-plot-series {
    display: none;
  }
`

export const ParkingsOccupationStats = function () {
  const { Stats } = useFetcherV2()
  const { searchParams } = useFetcher()
  const ref = useRef<HTMLDivElement>()
  const { t } = useTranslation()
  const [totals, setTotals] = useState<number[]>([])

  const { data: stats = null, isFetching } = useQuery({
    queryKey: ['stats', 'parkings-occupation', searchParams.toString()],
    queryFn: () => {
      if (!searchParams.get('center')) return null
      return Stats.parkingsOccupation(searchParams.toString())
    }
  })

  const refreshBoldText = useCallback(() => {
    if (ref.current && ref.current?.children) {
      const items = ref.current?.getElementsByClassName('apexcharts-xaxis-label')
      for (let item of items) {
        if (
          dayjs(item.getElementsByTagName('tspan')[0].innerHTML, 'MM.YYYY').isSame(dayjs(), 'month')
        ) {
          item.getElementsByTagName('tspan')[0].style.fontWeight = 'bold'
        }
      }
    }
  }, [ref])

  const { labels, series } = useMemo(() => {
    if (!stats) return { labels: [], series: [] }

    let series = {
      free: {
        name: t('stats_parkings_free'),
        data: [] as number[],
        type: 'bar',
        group: 'parking_slots'
      },
      offered: {
        name: t('stats_parkings_offered'),
        data: [] as number[],
        type: 'bar',
        group: 'parking_slots'
      },
      paid: {
        name: t('stats_parkings_paid'),
        data: [] as number[],
        type: 'bar',
        group: 'parking_slots'
      },
      total: {
        name: t('stats_parkings_occupation_rate'),
        data: [] as number[],
        type: 'line'
      }
    } as any

    let labels = [] as number[]

    let totals = [] as number[]
    Object.entries(stats[0]).forEach(([date, values]) => {
      labels.push(dayjs(date).toDate().getTime())
      let total = 0
      let occupied = 0
      Object.entries(values as object).forEach(([key, value]) => {
        if (series[String(key)]) {
          total += value
          if (key !== 'free') {
            occupied += value
          }
          series[String(key)].data.push(value as number)
        }
      })
      series.total.data.push(0)
      totals.push(total === 0 ? 0 : occupied / total)
    })

    setTotals(totals)

    const allSeries = [
      series.paid,
      series.offered,
      series.free,
      series.total
    ] as ApexAxisChartSeries
    return { labels, series: allSeries }
  }, [t, stats])

  const defaultOptions = useChartOptions()
  const options: ApexOptions = useMemo(() => {
    return merge(defaultOptions, {
      chart: {
        stackType: '100%',
        events: {
          mounted: refreshBoldText,
          updated: refreshBoldText
        }
      },
      legend: {
        formatter: (seriesName, opts) => {
          if (opts.seriesIndex === 3) return '' // hides first label
          return seriesName
        },
        markers: {
          size: [8, 8, 8, 0] as unknown as number,
          strokeWidth: 0
        }
      },
      plotOptions: {
        bar: {
          hideZeroBarsWhenGrouped: true
        }
      },
      stroke: {
        show: false
      },
      xaxis: {
        type: 'datetime',
        categories: labels,
        tickAmount: 12,
        labels: {
          format: 'MM.yyyy'
        },
        tooltip: {
          enabled: false
        },
        crosshairs: {
          show: false
        }
      },
      yaxis: {
        title: t('places'),
        labels: {
          formatter: (val) => {
            return formatRate(val)
          }
        },
        crosshairs: {
          show: false
        }
      },
      tooltip: {
        shared: true,
        intersect: false,
        x: {
          formatter: function (val) {
            return dayjs(val).format('MMMM YYYY')
          }
        },
        y: {
          title: {
            formatter: function (val) {
              return val + ' :'
            }
          },
          formatter: function (y, opts) {
            if (typeof y !== 'undefined') {
              if (opts.seriesIndex === opts.series.length - 1) {
                return formatPercentage(totals[opts.dataPointIndex])
              }
              return y.toFixed(2)
            }
            return y
          }
        }
      }
    } as ApexOptions)
  }, [t, refreshBoldText, labels, totals, defaultOptions])

  return (
    <Card sx={{ overflow: 'visible' }}>
      <CardContent>
        {isFetching ? (
          <CardSkeleton height={300} />
        ) : (
          <Box className="line">
            {series && (
              <Box ref={ref}>
                <StatsWithNoLine options={options} series={series} type="bar" height={300} />
              </Box>
            )}
          </Box>
        )}
      </CardContent>
    </Card>
  )
}
