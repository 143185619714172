import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Typography, Grid, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Event, FormItem, FormItems, Opportunity } from 'api/models'
import { Link } from 'app/components/link.component'
import { OpportunityTimeline } from 'modules/opportunities/components/opportunity-timeline.component'
import { OpportunityDetails } from 'modules/opportunities/components/opportunity-details.component'
import { useFetcher } from 'app/providers/fetcher.provider'
import { SolutionsComponent } from 'modules/opportunities/components/solutions.component'
import { ContractsComponent } from 'modules/opportunities/components/contracts.component'
import { UsersComponent } from 'modules/opportunities/components/users.component'
import { EventsComponent } from 'modules/opportunities/components/events.component'
import { Container } from '@mui/system'
import { useNavigate, useParams } from 'react-router-dom'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import SplitButton from 'app/components/buttons/split-button.component'
import dayjs from 'dayjs'
import { DialogRef } from 'app/components/dialog/dialog.component'
import { useSidebar } from 'app/providers/sidebar.provider'
import { EditEventDialog } from 'modules/events/views/edit-event-dialog.component'

export const OpportunityView = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { id } = useParams()
  const { setParams } = useSidebar()
  const [isLoading, setIsLoading] = useState(true)
  const [opportunity, setOpportunity] = useState<Opportunity>({} as Opportunity)
  const [options, setOptions] = useState({} as FormItems)
  const { getFormItems, getOpportunity } = useFetcher()
  const dialogRef = useRef<DialogRef>(null)
  const [commonOptions] = useState<Map<string, string>>(
    new Map<string, string>([
      ['opportunity_steps', 'steps'],
      ['centers', 'center'],
      ['event_types', 'type'],
      ['event_status', 'status'],
      ['prescriber_types', 'type'],
      ['opportunity_ground_refusals', 'opp_ground_refusal'],
      ['ground_refusal', 'ground_refusal']
    ])
  )

  const fetch = useCallback(async () => {
    setIsLoading(true)
    const data = await getOpportunity.mutateAsync(String(id))
    setOpportunity(data)
    setParams({
      id: String(data.enterpriseId),
      type: 'clients',
      data: { client: String(data.enterpriseId) }
    })
    setIsLoading(false)
  }, [id])

  const initOptions = useCallback(async (commonOptions: Map<string, string>) => {
    await getFormItems.mutateAsync(Array.from(commonOptions.keys() as any)).then((optionsData) => {
      setOptions(optionsData as FormItems)
    })
  }, [])

  useEffect(() => {
    initOptions(commonOptions).then(fetch)
  }, [id])

  if (isLoading) return <CardSkeleton />

  return (
    <Container data-cy="opportunity">
      <EditEventDialog
        selectedEvent={
          {
            statusId: '1',
            comment: '',
            enterpriseId: opportunity.enterpriseId,
            enterpriseName: opportunity.enterpriseName,
            staffName: opportunity.staffName,
            staffId: opportunity.staffId,
            beginLabel: dayjs().format('YYYY-MM-DD HH:mm'),
            ownerId: opportunity.userId,
            ownerName: opportunity.userName,
            typeId: 2,
            centerId: opportunity.centerId,
            opportunityId: opportunity.id,
            endLabel: dayjs().format('YYYY-MM-DD HH:mm')
          } as unknown as Event
        }
        options={options}
        dialogRef={dialogRef}
        onSuccess={fetch}
        isAdd={true}
      />
      <Typography variant="h1">
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Typography variant="h1">
            {t('opportunity')} |{' '}
            <Link to={`/enterprises/${opportunity.enterpriseId}`}>
              {opportunity.enterpriseName}
            </Link>
          </Typography>
          <SplitButton
            items={[
              {
                label: t('pricer'),
                key: 'pricer',
                action: () => navigate(`/pricer?opportunity=${opportunity.id}`)
              },
              {
                label: t('add_event'),
                key: 'add_event',
                action: () => dialogRef.current?.open()
              }
            ]}
          />
        </Stack>
      </Typography>
      <Typography variant="body2" marginTop={2}>
        {opportunity.reference}
      </Typography>

      <Stack direction={'row'} justifyContent={'end'} gap={3}>
        <Typography variant="body1" color="primary" textAlign={'right'} fontWeight={'bold'}>
          {t('member')}: {opportunity.staffName}
        </Typography>
        <Typography variant="body1" color="primary" textAlign={'right'} fontWeight={'bold'}>
          {t('created_by')}: {opportunity.staffName}
        </Typography>
        <Typography variant="body1" textAlign={'right'} fontWeight={'bold'}>
          {opportunity.statusLabel}
        </Typography>
      </Stack>

      <OpportunityTimeline
        step={opportunity.stepId}
        steps={options.opportunity_steps as FormItem}
      />

      <OpportunityDetails opportunity={opportunity} isLoading={isLoading} />

      <Grid container columns={12} paddingX={4} spacing={4}>
        <Grid item xs={6}>
          <Grid container columns={12} spacing={4}>
            <Grid item xs={12}>
              <SolutionsComponent data={opportunity} />
            </Grid>
            <Grid item xs={12}>
              <ContractsComponent data={opportunity} />
            </Grid>
            <Grid item xs={12}>
              <UsersComponent data={opportunity} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <EventsComponent data={opportunity} options={options} />
        </Grid>
      </Grid>
    </Container>
  )
}
